import React from "react"

const ImageBanner = ({title, text1, text2, backgroundImage}) => {
  return (
    <div id="banner-area" className="banner-area" style={{ backgroundImage: backgroundImage }}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="banner-heading">
              <h1 className="banner-title">{title}</h1>
              <ol className="breadcrumb">
                <li><a href="index.html">{text1}</a></li>
                <li>{text2}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageBanner
