import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
// import SweetAlert from "sweetalert2-react"
import ImageBanner from "../components/ImageBanner"
import ContactForm from "../components/ContactForm"
import Helmet from "react-helmet"

const ContactBanner = () => {
  return (
    <div id="banner-area" className="banner-area" style={{ backgroundImage: "url(images/banner/banner3.png)" }}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="banner-heading">
              <h1 className="banner-title">Contáctanos</h1>
              <ol className="breadcrumb">
                <li><a href="index.html">Inicio</a></li>
                <li>Contáctanos</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default function Contact() {
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>WGT | Evoluciona tu sala de juego</title>
        <meta name="description" content="Sistemas de cupones, promociones, ludopatia y mas"/>
      </Helmet>

      <Header/>

      {/*<ContactBanner/>*/}
      <ImageBanner
        title={"Contactanos"}
        text1={"Inicio"}
        text2={"Contactanos"}
        backgroundImage={"url(images/banner/banner3.png)"}
      />

      <ContactForm/>

      <Footer/>

    </div>
  )
}
